import React from 'react';
import profileImage from '../assets/profileimage.png';
import '../styles/Profile.css';
import { BriefcaseIcon, AtSymbolIcon, MapPinIcon } from '@heroicons/react/24/solid'


const Profile = () => {
    return (
      <div className="profile">
        <div className='header-profile'>
          <div className='sign'>
            <h2>Tommaso Giannoni</h2>
            <div className='desc'>

              <div className='desc-job'>
                <div className='icon-class icon-job'>
                  <BriefcaseIcon width="18" height="18"></BriefcaseIcon>
                </div>
                <div className='job-name'>
                  Software Engineer 
                </div>
              </div>


              <span className='desc-company'>
                <div className='icon-class icon-at'>
                  <AtSymbolIcon width="18" height="18"></AtSymbolIcon>
                </div>
                <span className='company-name'>FUJIFILM</span>
              </span>
            </div>
            <div className='paragraph'>
              <div className='icon-class icon-location'>
                <MapPinIcon width="18" height="18"></MapPinIcon>
              </div>
              <div className='location'>Italy, Europe</div>
            </div>
          </div>
          <img src={profileImage} alt="Profile" className="profile-image" />
        </div>
       

      </div>
    );
  }

export default Profile;